import * as React from "react";
import { HTMLProps } from "react";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { TextKey } from "../technical/contentful/text";
import * as moment from "moment";
import { OpacityLines } from "../components/OpacityLines";
import { useExternal } from "../technical/external-provider/content";
import { useContent } from "../technical/contentful/content";

interface Props {
  limit: number;
  className?: HTMLProps<HTMLDivElement>["className"];
}

export const Signers = ({ limit, className }: Props) => {
  const { texts } = useContent();
  const { signers } = useExternal();

  return (
    <OpacityLines
      className={className}
      lines={signers.slice(0, limit).map(({ id, firstname, datetime }) => ({
        line: (
          <p
            dangerouslySetInnerHTML={{
              __html: documentToPlainTextString(
                texts[TextKey.PETITION_SIGNED_USER].document
              )
                .replace(
                  "{{firstname}}",
                  `<span style="font-weight: 600">${firstname}</span>`
                )
                .replace("{{datetime}}", moment(datetime).fromNow()),
            }}
            style={{ marginTop: 8 }}
          />
        ),
        key: id,
      }))}
    />
  );
};
