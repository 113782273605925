import * as React from "react";
import { Petition } from "./Petition";
import styled from "styled-components";
import { handleCMSClick } from "../technical/handleCMSClick";
import { useContent } from "../technical/contentful/content";
import { TextKey } from "../technical/contentful/text";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { PRIMARY } from "../constant/Colors";
import { TABLET } from "../constant/Breakpoints";

const Container = styled.div`
  display: flex;
  max-width: 1280px;
  box-sizing: content-box;
  margin: 0 auto;
  padding: 48px 96px;

  @media (max-width: ${TABLET}px) {
    padding: 32px 24px;
    flex-direction: column;
  }

  > * {
    flex: 1;
    padding: 20px;
  }
`;

const Content = styled.div`
  white-space: pre-wrap;

  h1 {
    color: ${PRIMARY};
    text-transform: uppercase;
    font-size: 28px;
  }

  h2 {
    font-size: 26px;
    font-weight: bold;

    @media (max-width: ${TABLET}px) {
      font-size: 24px;
    }
  }

  h3 {
    font-size: 24px;

    @media (max-width: ${TABLET}px) {
      font-size: 22px;
    }
  }

  h4 {
    color: ${PRIMARY};
    margin-bottom: 24px;
  }

  p {
    font-size: 18px;

    @media (max-width: ${TABLET}px) {
      font-size: 20px;
    }
  }

  p:not(:empty):not(:first-child),
  h1,
  h2,
  h3,
  h4,
  h5 {
    :not(:first-child) {
      margin-top: 24px;
    }
  }

  ul {
    padding-inline-start: 40px;
    list-style: disc;
  }

  ul + p {
    margin-top: -44px;
  }

  b {
    font-weight: 600;
  }
`;

export const MainContent = () => {
  const { texts } = useContent();

  return (
    <Container className="flex-col-reverse md:flex-row">
      <Content
        dangerouslySetInnerHTML={{
          __html: documentToHtmlString(
            texts[TextKey.HOMEPAGE_CONTENT].document
          ),
        }}
        onClick={handleCMSClick}
      />
      <Petition />
    </Container>
  );
};
