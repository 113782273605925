import * as React from "react";
import { useCallback } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Count } from "../components/Count";
import { PRIMARY } from "../constant/Colors";
import { useContent } from "../technical/contentful/content";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { TextKey } from "../technical/contentful/text";
import { Button } from "../components/Button";
import { useForm } from "react-final-form";
import { TABLET } from "../constant/Breakpoints";

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: ${PRIMARY};
  color: white;
`;

const Content = styled.div`
  max-width: 600px;
  box-sizing: content-box;
  margin: 0 auto;
  padding: 48px 96px;

  @media (max-width: ${TABLET}px) {
    padding: 32px 24px;
  }

  position: relative;
  text-align: center;
  display: flex;
  align-item: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

const Title = styled.h1`
  font-size: 40px;
  font-weight: 800;
  margin-top: 19px;
`;

const Description = styled.p`
  font-size: 26px;
  font-weight: 500;
  margin-top: 19px;
`;

const CTADetails = styled(Description)`
  font-weight: 600;
  margin-bottom: 14px;
`;

const CTAs = styled.div`
  display: flex;

  @media (max-width: ${TABLET}px) {
    flex-direction: column;
  }
`;

const CTA = styled(Button)`
  font-weight: 800;
  font-size: 24px;
  text-transform: uppercase;
  padding: 20px;
  transition: all 0.3s ease;
  margin-top: 20px;
  width: initial;
  margin: 8px;
  border: 1px solid white;

  :active {
    border: 1px solid white;
    outline: none;
    text-decoration: underline;
  }

  :focus {
    border: 1px solid white;
    outline: none;
  }
`;

const CTAYes = styled(CTA)`
  background: white;
  color: ${PRIMARY};
`;

const CTANo = styled(CTA)`
  background: transparent;
  color: white;
`;

const Disclaimer = styled.p`
  font-size: 14px;
  margin-top: 14px;
`;

const BodyNoOverflow = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

interface Props {
  count: number;
}

export const OptIn = ({ count }: Props) => {
  const { submit, mutators } = useForm();
  const { texts } = useContent();

  const handleYes = useCallback(() => {
    mutators.setOptIn(true);
    return submit();
  }, [submit, mutators]);

  const handleNo = useCallback(() => {
    mutators.setOptIn(false);
    return submit();
  }, [submit, mutators]);

  return (
    <Container>
      <BodyNoOverflow />
      <Content>
        {count >= 10_000 && <Count count={count} />}

        <Title>
          {documentToPlainTextString(texts[TextKey.FORM_OPTIN_TITLE].document)}
        </Title>
        <Description>
          {documentToPlainTextString(
            texts[TextKey.FORM_OPTIN_DESCRIPTION].document
          )}
        </Description>
        <CTADetails>
          {documentToPlainTextString(
            texts[TextKey.FORM_OPTIN_CTA_DETAILS].document
          )}
        </CTADetails>
        <CTAs>
          <CTAYes onClick={handleYes}>
            {documentToPlainTextString(
              texts[TextKey.FORM_OPTIN_CTA_YES].document
            )}
          </CTAYes>
          <CTANo onClick={handleNo}>
            {documentToPlainTextString(
              texts[TextKey.FORM_OPTIN_CTA_NO].document
            )}
          </CTANo>
        </CTAs>
        <Disclaimer>
          {documentToPlainTextString(
            texts[TextKey.FORM_OPTIN_DISCLAIMER].document
          )}
        </Disclaimer>
      </Content>
    </Container>
  );
};
