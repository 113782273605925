import * as React from "react";

export const Lock = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 15 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.55718 12.5337a1.53363 1.53363 0 0 0-.9427.333 1.5337 1.5337 0 0 0-.4404 1.8384c.14249.3116.38522.5665.68947.724v1.82h1.27273v-1.7627a1.53369 1.53369 0 0 0 .9234-1.7143 1.53346 1.53346 0 0 0-.52949-.8882 1.53301 1.53301 0 0 0-.97301-.3502Z"
      fill="#fff"
    />
    <path
      d="M12.5909 8.97636V6.25272a5.21832 5.21832 0 0 0-1.4471-3.71109C10.1898 1.54461 8.87957.96611 7.5.93273a5.21817 5.21817 0 0 0-5.09091 5.31999v2.72364H.5V19.7945a1.27277 1.27277 0 0 0 1.27273 1.2728H13.2273c.3375 0 .6612-.1341.8999-.3728.2387-.2387.3728-.5624.3728-.9V8.97636h-1.9091ZM3.68182 6.25272A3.94544 3.94544 0 0 1 7.5 2.20545a3.94549 3.94549 0 0 1 2.744 1.23599 3.94579 3.94579 0 0 1 1.0742 2.81128v2.72364H3.68182V6.25272ZM1.77273 19.7945v-9.5454H13.2273v9.5454H1.77273Z"
      fill="#fff"
    />
  </svg>
);
