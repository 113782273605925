import "../custom-types/assets.d";
import * as React from "react";
import Helmet from "react-helmet";
import favicon from "../assets/images/favicon.png";
import { ContentProvider } from "../technical/contentful/ContentProvider";
import { useContent } from "../technical/contentful/content";
import { Footer } from "../section/Footer";
import { MainContent } from "../section/MainContent";
import "../technical/firestore";
import { ExternalProvider } from "../technical/external-provider/ContentProvider";
import { useExternal } from "../technical/external-provider/content";
import { Header } from "../components/Header";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { TextKey } from "../technical/contentful/text";
import styled from "styled-components";
import { Link } from "gatsby";
import { PRIMARY } from "../constant/Colors";
import { ContentfulImage } from "../components/ContentfulImage";

const CTA = styled(Link)`
  display: inline-block;
  font-weight: 800;
  font-size: 24px;
  text-transform: uppercase;
  padding: 20px;
  border: 1px solid white;
  transition: all 0.3s ease;
  margin-top: 20px;

  :hover {
    color: ${PRIMARY};
    background: white;
  }
`;

const Main = () => {
  const { texts, logo, seo } = useContent();
  const { signersCount } = useExternal();

  return (
    <>
      <Helmet
        title={seo.title}
        link={[{ rel: "icon", href: favicon }]}
        htmlAttributes={{
          lang: "fr",
        }}
        meta={[
          {
            name: "description",
            content: seo.description,
          },
          {
            name: "viewport",
            content: "width=device-width, initial-scale=0.7",
          },
          { property: "og:url", content: "https://zemmourcestnon.fr/" },
          { property: "og:type", content: "website" },
          { property: "og:title", content: seo.title },
          {
            property: "og:description",
            content: seo.description,
          },
          {
            property: "og:image",
            content: `https:${seo.image}`,
          },
          { property: "og:locale", content: "FR" },
          { property: "twitter:card", content: "summary_large_image" },
        ]}
      />
      <Header
        maxWidth={600}
        count={signersCount}
        title={documentToPlainTextString(
          texts[TextKey.HEADER_BEFORE_LOGO].document
        )}
        subtitle={documentToPlainTextString(
          texts[TextKey.HEADER_AFTER_LOGO].document
        )}
        img={
          <ContentfulImage
            image={logo}
            style={{ margin: -15 }}
            className="mx-auto w-auto h-72"
          />
        }
        cta={
          <CTA to="/#petition">
            {documentToPlainTextString(texts[TextKey.HEADER_CTA].document)}
          </CTA>
        }
      />
      <MainContent />
      <Footer />
    </>
  );
};

export default () => (
  <ContentProvider>
    <ExternalProvider>
      <Main />
    </ExternalProvider>
  </ContentProvider>
);
