import * as React from "react";
import { HTMLProps, useCallback, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { canSubmit, processError, validate } from "../technical/form";
import styled from "styled-components";
import { Button as BaseButton } from "../components/Button";
import * as Input from "../components/input";
import * as moment from "moment";
import "moment/locale/fr";
import { useExternal } from "../technical/external-provider/content";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { useContent } from "../technical/contentful/content";
import { TextKey } from "../technical/contentful/text";
import { Signers as BaseSigners } from "./Signers";
import { Lock } from "../assets/images/Lock";
import { PRIMARY } from "../constant/Colors";
import { Gauge, getNextGoal } from "./Gauge";
import { navigate } from "gatsby";
import { TABLET } from "../constant/Breakpoints";
import { OptIn } from "./OptIn";
import { Skeleton } from "../components/Skeleton";

moment.locale("fr");

const FormTitle = styled.h2`
  font-size: 30px;
  line-height: 30px;
  font-weight: 600;
  color: ${PRIMARY};
  margin-bottom: 8px;

  @media (max-width: ${TABLET}px) {
    font-size: 32px;
    line-height: 32px;
  }
`;

const Signers = styled(BaseSigners)`
  min-height: 120px;
`;

const HiddenInput = styled.input`
  display: none;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

const Button = styled(BaseButton)`
  margin-top: 24px;
`;

interface Values {
  FIRSTNAME: string;
  EMAIL: string;
  OPT_IN: boolean;
  POSTAL: number;
}

interface Props {
  className?: HTMLProps<HTMLDivElement>["className"];
}

export const Petition = ({ className }: Props) => {
  const { texts } = useContent();
  const { signersCount, ready } = useExternal();

  const [optInVisible, setOptInVisible] = useState(false);
  const setShowOptIn = useCallback(() => setOptInVisible(true), [
    setOptInVisible,
  ]);
  const setHideOptIn = useCallback(() => setOptInVisible(false), [
    setOptInVisible,
  ]);

  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = useCallback(
    async (values: Values) => {
      if (!optInVisible) {
        return setShowOptIn();
      }

      setHideOptIn();
      const formData = new FormData(formRef.current);

      const urlSearchParams = new URLSearchParams(window.location.search);
      const ref = urlSearchParams.get("ref");
      if (ref) {
        formData.set("REF", ref);
      }
      formData.set("OPT_IN", values.OPT_IN ? "1" : "");

      await fetch(process.env.SEND_IN_BLUE_FORM, {
        method: "POST",
        body: formData,
        mode: "no-cors",
      });
      await navigate(
        `/partage?email=${encodeURIComponent(
          values.EMAIL
        )}&firstname=${encodeURIComponent(values.FIRSTNAME)}`
      );
    },
    [optInVisible, setHideOptIn, setShowOptIn]
  );

  return (
    <div id="petition" className={className}>
      {ready ? (
        <>
          <FormTitle>
            {documentToPlainTextString(texts[TextKey.PETITION_TITLE].document)
              .replace("{{count}}", String(signersCount))
              .replace("{{goal}}", String(getNextGoal(signersCount)))}
          </FormTitle>
          <Gauge />
          <Signers limit={5} />
        </>
      ) : (
        <Skeleton lines={6} />
      )}
      <Form
        onSubmit={handleSubmit}
        validate={validate({
          FIRSTNAME: {
            presence: {
              message: "^Le prénom est requis.",
            },
          },
          EMAIL: {
            email: {
              message: "^L'email n'est pas valide.",
            },
            presence: {
              message: "^L'email est requis.",
            },
          },
          POSTAL: {
            presence: {
              message: "^Le code postal est requis.",
            },
            length: {
              minimum: 3,
              message: "^Le code postal doit faire plus de 3 caractères.",
            },
            numericality: {
              onlyInteger: true,
              greaterThan: 0,
              message: "^Le code postal n'est pas valide",
            },
          },
        })}
        mutators={{
          setOptIn: (args, state, utils) => {
            utils.changeValue(state, "OPT_IN", () => args[0]);
          },
        }}
      >
        {formProps => (
          <form ref={formRef} onSubmit={formProps.handleSubmit}>
            <InputContainer>
              <Field name="FIRSTNAME" type="text">
                {({ input, meta }) => {
                  const error = processError(meta);

                  return (
                    <Input.Errored error={error}>
                      <Input.Text
                        {...input}
                        disabled={formProps.submitting}
                        error={!!error}
                        placeholder={documentToPlainTextString(
                          texts[TextKey.FORM_FIRSTNAME].document
                        )}
                      />
                    </Input.Errored>
                  );
                }}
              </Field>
            </InputContainer>
            <InputContainer>
              <Field name="EMAIL" type="text">
                {({ input, meta }) => {
                  const error = processError(meta);

                  return (
                    <Input.Errored error={error}>
                      <Input.Text
                        {...input}
                        disabled={formProps.submitting}
                        error={!!error}
                        placeholder={documentToPlainTextString(
                          texts[TextKey.FORM_EMAIL].document
                        )}
                      />
                    </Input.Errored>
                  );
                }}
              </Field>
            </InputContainer>
            <InputContainer>
              <Field name="POSTAL" type="text">
                {({ input, meta }) => {
                  const error = processError(meta);

                  return (
                    <Input.Errored error={error}>
                      <Input.Text
                        {...input}
                        disabled={formProps.submitting}
                        error={!!error}
                        placeholder={documentToPlainTextString(
                          texts[TextKey.FORM_POSTAL].document
                        )}
                      />
                    </Input.Errored>
                  );
                }}
              </Field>
            </InputContainer>
            <HiddenInput type="text" name="email_address_check" value="" />
            <HiddenInput type="hidden" name="locale" value="fr" />
            <HiddenInput type="hidden" name="html_type" value="simple" />
            <Button
              icon={<Lock width={14} height={20} />}
              disabled={!canSubmit(formProps)}
              loading={formProps.submitting}
            >
              {documentToPlainTextString(texts[TextKey.FORM_SUBMIT].document)}
            </Button>
            {optInVisible && <OptIn count={signersCount} />}
          </form>
        )}
      </Form>
    </div>
  );
};
