import * as React from "react";
import styled from "styled-components";
import { TABLET } from "../../constant/Breakpoints";

interface Props {
  error?: boolean;
}

export const Text = styled.input<Props>`
  width: 100%;
  background: #dedfff;
  padding: 18px 14px;
  outline: none;
  font-size: 18px;
  font-weight: 600;
  color: #1d23b5;

  :invalid {
    box-shadow: none;
  }

  :placeholder {
    font-weight: bold;
    color: rgba(29, 35, 181, 0.5);
  }

  @media (max-width: ${TABLET}px) {
    font-size: 20px;
  }
`;
